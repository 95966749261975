define("discourse/plugins/discourse-locations/discourse/routes/users-user-map", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    redirect() {
      if (!this.siteSettings.location_users_map) {
        this.replaceWith("/u");
      }
    },
    model() {
      let params = {
        period: "location"
      };
      return this.store.find("directoryItem", params);
    },
    setupController(controller, model) {
      controller.set("userList", model.content);
    },
    renderTemplate() {
      this.render("users/user-map", {
        into: "application"
      });
    }
  });
});