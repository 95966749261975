define("discourse/plugins/discourse-locations/discourse/initializers/location-map-url-redirects", ["exports", "discourse/lib/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "location-map-url-redirects",
    after: "url-redirects",
    initialize() {
      _url.default.rewrite(/^\/u\/user\-map\/summary/, "/u/user-map");
      _url.default.rewrite(/^\/users\/user\-map\/summary/, "/users/user-map");
    }
  };
});