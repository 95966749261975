define("discourse/plugins/discourse-locations/discourse/templates/connectors/users-top/map-link", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if siteSettings.location_users_map}}
    <div class="container users-nav">
      <ul class="nav nav-pills">
        <li>
          {{#link-to "users.index"}}
            {{i18n "directory.list.title"}}
          {{/link-to}}
        </li>
        <li>
          {{#link-to "users.user-map"}}
            {{i18n "directory.map.title"}}
          {{/link-to}}
        </li>
      </ul>
    </div>
  {{/if}}
  */
  {
    "id": "JbHtwPws",
    "block": "[[[41,[30,0,[\"siteSettings\",\"location_users_map\"]],[[[1,\"  \"],[10,0],[14,0,\"container users-nav\"],[12],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,1],null,[[\"route\"],[\"users.index\"]],[[\"default\"],[[[[1,\"          \"],[1,[28,[35,2],[\"directory.list.title\"],null]],[1,\"\\n\"]],[]]]]],[1,\"      \"],[13],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,1],null,[[\"route\"],[\"users.user-map\"]],[[\"default\"],[[[[1,\"          \"],[1,[28,[35,2],[\"directory.map.title\"],null]],[1,\"\\n\"]],[]]]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-locations/discourse/templates/connectors/users-top/map-link.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/templates/connectors/users-top/map-link.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});